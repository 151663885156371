<template>
  <div class="container lefttext fill gaphead" style="background: #f3f3f3">
    <div
      class="row wocommerce-heading"
      style="
        background: #f3f3f3;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px dashed #cccccc;
      "
    >
      <div
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
        style="margin: 10px 0 10px 0"
      >
        Register for Connect
      </div>
    </div>
    <div
      class="row"
      style="background: #f3f3f3; padding-top: 10px; padding-bottom: 10px"
    >
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div class="form-group">
              <label>{{ langFields.country }}<span>*</span></label>
              <select
                class="form-control"
                v-model="selectedCountry"
                @change.prevent="checkAndChangeDefaultLanguage"
              >
                <option value="0">Select</option>
                <option v-for="item in $func.country" :key="item" :value="item">
                  {{ item }}
                </option>
              </select>
              <label
                class="text-danger"
                v-show="!visiblefields.isvalidcountry"
                >{{ langMessages.validcountry }}</label
              >
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div class="form-group">
              <label
                ><s v-if="stateList.length <= 0 && false">{{
                  langFields.state
                }}</s
                ><span style="color: #000000" v-else>{{
                  langFields.state
                }}</span
                ><span v-if="stateList.length > 0">*</span></label
              >
              <select
                class="form-control"
                v-model="selectedState"
                @change.prevent="checkAndChangeAreaOnStateChange"
                :disabled="stateList.length <= 0"
              >
                <option value="0">Select</option>
                <option
                  v-for="stateitemitem in stateList"
                  :key="stateitemitem"
                  :value="stateitemitem"
                >
                  {{ stateitemitem }}
                </option>
              </select>
              <label
                class="text-danger"
                v-show="stateList.length > 0 && !visiblefields.isvalidstate"
                >{{ langMessages.validstate }}</label
              >
            </div>
          </div>
          <div
            v-if="visiblefields.area"
            class="col-lg-4 col-md-4 col-sm-6 col-xs-12"
          >
            <div class="form-group">
              <label
                ><s v-if="commAreaList.length <= 0 && false">{{
                  langFields.area
                }}</s
                ><span style="color: #000000" v-else>{{ langFields.area }}</span
                ><span v-if="commAreaList.length > 0">*</span></label
              >
              <select
                class="form-control"
                v-model="selectedCommArea"
                @change.prevent="checkAreaValidity"
                :disabled="commAreaList.length <= 0"
              >
                <option value="0">Select</option>
                <option
                  v-for="commareaitem in commAreaList"
                  :key="commareaitem"
                  :value="commareaitem"
                >
                  {{ commareaitem }}
                </option>
              </select>
              <label
                class="text-danger"
                v-show="commAreaList.length > 0 && !visiblefields.isvalidarea"
                >{{ langMessages.validarea }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      style="background: #f3f3f3; padding-top: 10px; padding-bottom: 10px"
    >
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div class="form-group">
              <label>{{ langFields.firstname }}<span>*</span></label>
              <input
                class="form-control"
                v-model="firstName"
                placeholder=""
                type="text"
              />
              <label
                class="text-danger"
                v-show="
                  firstName !== undefined &&
                  firstName !== null &&
                  firstName.length <= 0
                "
                >{{ langMessages.firstnameempty }}</label
              >
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div class="form-group">
              <label>{{ langFields.lastname }}<span>*</span></label>
              <input
                class="form-control"
                v-model="lastName"
                placeholder=""
                type="text"
              />
              <label
                class="text-danger"
                v-show="
                  lastName !== undefined &&
                  lastName !== null &&
                  lastName.length <= 0
                "
                >{{ langMessages.lastnameempty }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      style="background: #f3f3f3; padding-top: 10px; padding-bottom: 10px"
    >
      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <div class="form-group">
          <label>{{ langFields.email }}<span>*</span></label>
          <input
            class="form-control"
            v-model="userEmail"
            @keyup.prevent="checkForValidEmail"
            placeholder=""
            type="text"
          />
          <label
            class="text-danger"
            v-show="
              (userEmail !== undefined &&
                userEmail !== null &&
                userEmail.length <= 0) ||
              !visiblefields.isvalidmail
            "
            >{{ langMessages.validemail }}</label
          >
          <label class="text-danger" v-show="!visiblefields.isvalidExtmail">{{
            langMessages.invalidinternalmail
          }}</label>
          <label class="text-secondary" style="font-size: 0.9em">{{
            langMessages.validemailsuggestion
          }}</label>
        </div>
      </div>
      <div v-if="false" class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <div class="form-group">
          <label>{{ langFields.password }}<span>*</span></label>
          <input class="form-control" placeholder="" type="password" />
        </div>
      </div>
      <div v-if="false" class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <div class="form-group">
          <label>{{ langFields.verifypassword }}<span>*</span></label>
          <input class="form-control" placeholder="" type="password" />
        </div>
      </div>
      <div
        v-if="visiblefields.beamsuntorysponsor"
        class="col-lg-4 col-md-4 col-sm-6 col-xs-12"
      >
        <div class="form-group">
          <label>{{ langFields.sponseremail }}<span>*</span></label>
          <input
            class="form-control"
            v-model="beamSponsorEmail"
            @keyup.prevent="checkForValidSponsorEmail"
            placeholder=""
            type="text"
          />
          <label
            class="text-danger"
            v-show="
              (beamSponsorEmail !== undefined &&
                beamSponsorEmail !== null &&
                beamSponsorEmail.length <= 0) ||
              !visiblefields.isvalidBeamSponsorMail
            "
            >{{ langMessages.validemail }}</label
          >
        </div>
      </div>
      <div
        v-if="visiblefields.brand"
        class="col-lg-4 col-md-4 col-sm-6 col-xs-12"
      >
        <div class="form-group">
          <label>{{ langFields.brand }}<span>*</span></label>
          <input
            class="form-control"
            v-model="beamBrand"
            @keyup.prevent="checkForValidBrand"
            placeholder=""
            type="text"
          />
          <label
            class="text-danger"
            v-show="
              (beamBrand !== undefined &&
                beamBrand !== null &&
                beamBrand.length <= 0) ||
              !visiblefields.isvalidBeamBrand
            "
            >{{ langMessages.validbrand }}</label
          >
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <div class="form-group">
          <label>{{ langFields.birthdate }}<span>*</span></label>
          <date-picker
            v-model="selectedBirthdate"
            valueType="date"
            format="MM/DD/YYYY"
            :editable="false"
            :disabled-date="disabledRange"
          ></date-picker>
        </div>
      </div>
    </div>
    <div
      class="row"
      style="background: #f3f3f3; padding-top: 10px; padding-bottom: 10px"
    >
      <div
        v-if="visiblefields.premise"
        class="col-lg-4 col-md-4 col-sm-6 col-xs-12"
      >
        <div class="form-group">
          <label>{{ langFields.premise }}<span>*</span></label>
          <select v-model="onOffPremise" class="form-control">
            <option
              v-for="item in langValues.onOffPermiseVals"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <div class="form-group">
          <label>{{ langFields.employer }}<span>*</span></label>
          <select
            v-if="
              visiblefields.employer &&
              selectedCountry.trim().toLowerCase() !== 'germany'
            "
            v-model="selectedEmployer"
            @change.prevent="checkForValidEmployer"
            class="form-control"
          >
            <option value="0">Select</option>
            <option
              v-for="item in masterRecordSet.item2"
              :key="item"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
          <input
            v-if="
              !visiblefields.employer ||
              selectedCountry.trim().toLowerCase() === 'germany'
            "
            :value="inputEmployer"
            @input.prevent="selectedEmployer = $event.target.value"
            @keyup.prevent="checkForInvalidSymbolsInEmployer"
            class="form-control"
            style="text-transform: uppercase"
            placeholder=""
            type="text"
          />
          <label
            class="text-danger"
            v-show="
              (selectedEmployer !== undefined &&
                selectedEmployer !== null &&
                selectedEmployer.length <= 0 &&
                selectedEmployer !== '0') ||
              !visiblefields.isValidEmployer
            "
            >{{ langMessages.validemployer }}</label
          >
        </div>
      </div>
    </div>
    <div
      class="row"
      style="
        background: #f3f3f3;
        padding-top: 10px;
        padding-bottom: 50px;
        border-top: 1px dashed #cccccc;
      "
    >
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="pull-right">
          <button
            class="btn btn-outline-primary beamsavebutton"
            @click.prevent="validateDataAndSave"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "regform-field",
  components: { DatePicker },
  data() {
    return {
      masterRecordSet: {
        item1: [
          {
            country: null,
            states: [],
          },
        ],
        item2: [],
      },
      langFields: this.$en.fields,
      langValues: this.$en.values,
      langMessages: this.$en.messages,
      validationTracker: false,
      visiblefields: {
        area: false,
        beamsuntorysponsor: false,
        premise: false,
        employer: false,
        brand: false,
        isvalidcountry: true,
        isvalidstate: true,
        isvalidarea: true,
        isvalidmail: true,
        isvalidExtmail: true,
        isvalidBeamSponsorMail: true,
        isvalidBeamBrand: true,
        isValidEmployer: true,
      },
      firstName: null,
      lastName: null,
      userEmail: null,
      beamSponsorEmail: null,
      beamBrand: null,
      selectedEmployer: "0",
      selectedCountry: "0",
      selectedState: "0",
      onOffPremise: this.$en.values.onOffPermiseVals[0].value,
      stateList: [],
      selectedCommArea: "0",
      commAreaList: [],
      selectedBirthdate: this.$func.addDate(new Date(), -21, "years"),
    };
  },
  watch: {
    selectedCountry: function () {
      const _vm = this;
      _vm.stateList = [];
      if (_vm.selectedCountry !== "0") {
        _vm.stateList = _vm.$func.states[_vm.selectedCountry];
      }
      if (
        _vm.stateList === undefined ||
        _vm.stateList === null ||
        _vm.selectedCountry === "0"
      ) {
        _vm.stateList = [];
        _vm.selectedState = "0";
        _vm.selectedCommArea = "0";
      }
    },
    selectedState: function () {
      const _vm = this;
      _vm.commAreaList = [];
      if (_vm.selectedState !== "0") {
        _vm.commAreaList = _vm.$func.areas[_vm.selectedState];
      }
      if (
        _vm.commAreaList === undefined ||
        _vm.commAreaList === null ||
        _vm.selectedState === "0"
      ) {
        _vm.commAreaList = [];
        _vm.selectedCommArea = "0";
      }
    },
  },
  computed: {
    inputEmployer: function () {
      const _vm = this;
      return _vm.selectedEmployer === undefined ||
        _vm.selectedEmployer === null ||
        _vm.selectedEmployer === "0"
        ? null
        : _vm.selectedEmployer;
    },
    employersList: function () {
      const _vm = this;
      let empArr = [];
      switch (localStorage.getItem("selectedUserType") * 1) {
        case _vm.$func.usertype.distributor:
        case _vm.$func.usertype.beamSuntoryContractor: {
          _vm.$func.employers.Dist_ID.forEach((element) => {
            empArr.push(element);
          });
          break;
        }
        case _vm.$func.usertype.tradePartner:
        case _vm.$func.usertype.promoAgency: {
          _vm.$func.employers.Trade_ID.forEach((element) => {
            empArr.push(element);
          });
          break;
        }
        default:
          break;
      }
      return empArr;
    },
  },
  mounted() {
    const _vm = this;
    // eslint-disable-next-line no-undef
    $(".mx-input")
      .removeAttr("style")
      .attr(
        "style",
        "height: 40px !important; border-radius: 5 !important; color: #959595 !important; background-color: #ffffff !important;"
      );
    _vm.$store.state.selectedUserType =
      localStorage.getItem("selectedUserType") * 1;
    _vm.setVisibleFields(localStorage.getItem("selectedUserType") * 1);
    _vm.fetchMasterData();
  },
  methods: {
    checkForValidCountry: function () {
      const _vm = this;
      _vm.visiblefields.isvalidcountry =
        _vm.selectedCountry !== undefined &&
        _vm.selectedCountry !== null &&
        _vm.selectedCountry !== "0";
      _vm.$nextTick(function () {
        _vm.checkForValidState();
      });
    },
    checkForValidState: function () {
      const _vm = this;
      _vm.visiblefields.isvalidstate =
        _vm.selectedState !== undefined &&
        _vm.selectedState !== null &&
        _vm.selectedState !== "0";
      _vm.$nextTick(function () {
        _vm.checkForValidArea();
      });
    },
    checkForValidArea: function () {
      const _vm = this;
      _vm.visiblefields.isvalidarea =
        _vm.selectedCommArea !== undefined &&
        _vm.selectedCommArea !== null &&
        _vm.selectedCommArea !== "0";
    },
    checkForValidEmail: function () {
      const _vm = this;
      _vm.visiblefields.isvalidmail = _vm.$func.validateEmail(_vm.userEmail);
      /*if (_vm.visiblefields.isvalidmail) {
        _vm.visiblefields.isvalidExtmail = _vm.$func.validateEmailIsNotInternal(
          _vm.userEmail
        );
      } else {
        _vm.visiblefields.isvalidExtmail = true;
      }*/
    },
    checkForValidSponsorEmail: function () {
      const _vm = this;
      _vm.visiblefields.isvalidBeamSponsorMail =
        _vm.$func.validateEmail(_vm.beamSponsorEmail) &&
        _vm.$func.validateBeamSuntorySponsorEmail(_vm.beamSponsorEmail);
    },
    checkForValidBrand: function () {
      const _vm = this;
      _vm.visiblefields.isvalidBeamBrand =
        _vm.beamBrand !== undefined &&
        _vm.beamBrand !== null &&
        _vm.beamBrand.length > 0;
    },
    checkForInvalidSymbolsInEmployer: function () {
      const _vm = this;
      _vm.visiblefields.isValidEmployer =
        _vm.$func.validateSpecialCharactersForEmployer(_vm.selectedEmployer);
    },
    checkForValidEmployer: function () {
      const _vm = this;
      _vm.visiblefields.isValidEmployer =
        _vm.selectedEmployer !== undefined &&
        _vm.selectedEmployer !== null &&
        _vm.selectedEmployer.length > 0 &&
        _vm.selectedEmployer !== "0";
    },
    setVisibleFields: function (usertype) {
      const _vm = this;
      _vm.visiblefields.area =
        usertype === _vm.$func.usertype.distributor ||
        usertype === _vm.$func.usertype.beamSuntoryContractor;
      /*_vm.visiblefields.beamsuntorysponsor =
        usertype === _vm.$func.usertype.distributor ||
        usertype === _vm.$func.usertype.beamSuntoryContractor;*/
      _vm.visiblefields.beamsuntorysponsor = true;
      _vm.visiblefields.premise =
        usertype === _vm.$func.usertype.distributor ||
        usertype === _vm.$func.usertype.beamSuntoryContractor ||
        usertype === _vm.$func.usertype.tradePartner;
      _vm.visiblefields.employer =
        usertype === _vm.$func.usertype.distributor ||
        usertype === _vm.$func.usertype.beamSuntoryContractor ||
        usertype === _vm.$func.usertype.tradePartner ||
        usertype === _vm.$func.usertype.promoAgency;
      _vm.visiblefields.brand = usertype === _vm.$func.usertype.default;
    },
    disabledRange: function (date) {
      const _vm = this;
      return date >= _vm.$func.addDate(new Date(), -21, "years");
    },
    resetStatesAndArea: function () {
      const _vm = this;
      _vm.selectedState = "0";
      _vm.selectedCommArea = "0";
    },
    resetArea: function () {
      const _vm = this;
      _vm.selectedCommArea = "0";
    },
    checkAndChangeDefaultLanguage: function () {
      const _vm = this;
      if (_vm.selectedCountry.trim().toLowerCase() === "germany") {
        _vm.langFields = _vm.$de.fields;
        _vm.langValues = _vm.$de.values;
        _vm.langMessages = _vm.$de.messages;
      } else {
        _vm.langFields = _vm.$en.fields;
        _vm.langValues = _vm.$en.values;
        _vm.langMessages = _vm.$en.messages;
      }
      _vm.checkForValidCountry();
      _vm.resetStatesAndArea();
    },
    checkAndChangeAreaOnStateChange: function () {
      const _vm = this;
      _vm.checkForValidState();
      _vm.resetArea();
    },
    checkAreaValidity: function () {
      const _vm = this;
      _vm.checkForValidArea();
    },
    validateDataAndSave: function () {
      const _vm = this;
      _vm.validateFinalData();
      _vm.$nextTick(function () {
        if (_vm.validationTracker) {
          _vm.saveDataToMS();
        }
      });
    },
    saveDataToMS: function () {
      const _vm = this;
      _vm
        .$axios({
          url: process.env.VUE_APP_SERVER_API + "api/ConnectRegister",
          method: "POST",
          data: {
            givenName: _vm.firstName,
            surname: _vm.lastName,
            country: _vm.selectedCountry,
            state: _vm.selectedState === "0" ? null : _vm.selectedState,
            extension_be6dc6c96b4c411780751b4231962926_Area:
              _vm.selectedCommArea === "0" ? null : _vm.selectedCommArea,
            mail: _vm.userEmail,
            extension_be6dc6c96b4c411780751b4231962926_Email: _vm.userEmail,
            extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor:
              _vm.beamSponsorEmail,
            extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand:
              _vm.beamBrand,
            extension_be6dc6c96b4c411780751b4231962926_Birthdate:
              _vm.selectedBirthdate,
            extension_be6dc6c96b4c411780751b4231962926_OnOffPremise:
              _vm.onOffPremise,
            extension_be6dc6c96b4c411780751b4231962926_Employer:
              _vm.selectedEmployer.toUpperCase(),
            sfrole: _vm.$store.state.selectedUserType,
          },
        })
        .then((response) => {
          if (response.data.success) {
            _vm.$func
              .sAlert(response.data.message, "success", "", _vm.$swal)
              .then((ok) => {
                if (ok) {
                  _vm.$router.push({
                    path: "/",
                  });
                }
              });
          } else {
            _vm.$func
              .sAlert(response.data.message, "error", "", _vm.$swal)
              .then((ok) => {
                if (ok) {
                  _vm.$router.push({
                    path: "/",
                  });
                }
              });
          }
        })
        .catch(function (error) {
          _vm.$func.sAlert(
            "An error occurred",
            "error",
            error.response.data.message,
            _vm.$swal
          );
        });
    },
    validateFinalData: function () {
      const _vm = this;
      _vm.firstName =
        _vm.firstName === undefined || _vm.firstName === null
          ? ""
          : _vm.firstName;
      _vm.lastName =
        _vm.lastName === undefined || _vm.lastName === null ? "" : _vm.lastName;
      _vm.checkForValidCountry();
      _vm.checkForValidState();
      if (_vm.visiblefields.area) {
        _vm.checkForValidArea();
      }
      _vm.checkForValidEmail();
      if (_vm.visiblefields.beamsuntorysponsor) {
        _vm.checkForValidSponsorEmail();
      }
      if (_vm.visiblefields.brand) {
        _vm.checkForValidBrand();
      }
      if (
        _vm.visiblefields.employer &&
        _vm.selectedCountry.trim().toLowerCase() !== "germany"
      ) {
        _vm.checkForValidEmployer();
      } else if (
        _vm.visiblefields.employer === false ||
        _vm.selectedCountry.trim().toLowerCase() === "germany"
      ) {
        _vm.selectedEmployer =
          _vm.selectedEmployer === "0" ? null : _vm.selectedEmployer;
        _vm.checkForInvalidSymbolsInEmployer();
      }
      _vm.$nextTick(function () {
        if (
          _vm.firstName.length <= 0 ||
          _vm.lastName <= 0 ||
          !_vm.visiblefields.isvalidcountry ||
          !_vm.visiblefields.isvalidmail ||
          !_vm.visiblefields.isvalidExtmail
        ) {
          _vm.validationTracker = false;
        } else if (
          _vm.stateList.length > 0 &&
          !_vm.visiblefields.isvalidstate
        ) {
          _vm.validationTracker = false;
        } else if (
          _vm.visiblefields.brand &&
          !_vm.visiblefields.isvalidBeamBrand
        ) {
          _vm.validationTracker = false;
        } else if (
          _vm.commAreaList.length > 0 &&
          _vm.visiblefields.area &&
          !_vm.visiblefields.isvalidarea
        ) {
          _vm.validationTracker = false;
        } else if (
          _vm.visiblefields.beamsuntorysponsor &&
          !_vm.visiblefields.isvalidBeamSponsorMail
        ) {
          _vm.validationTracker = false;
        } else if (
          _vm.visiblefields.brand &&
          !_vm.visiblefields.isvalidBeamBrand
        ) {
          _vm.validationTracker = false;
        } else if (
          _vm.visiblefields.employer &&
          _vm.selectedCountry.trim().toLowerCase() !== "germany" &&
          !_vm.visiblefields.isValidEmployer
        ) {
          _vm.validationTracker = false;
        } else if (
          (_vm.visiblefields.employer === false ||
            _vm.selectedCountry.trim().toLowerCase() === "germany") &&
          !_vm.visiblefields.isValidEmployer
        ) {
          _vm.validationTracker = false;
        } else {
          _vm.validationTracker = true;
        }
      });
    },
    fetchMasterData: function () {
      const _vm = this;
      _vm
        .$axios({
          url: process.env.VUE_APP_SERVER_API + "api/SpointMasterProvider",
          method: "GET",
        })
        .then((response) => {
          _vm.$nextTick(() => {
            _vm.masterRecordSet = response.data.message;
            _vm.fetchEmployersByUserType();
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    fetchEmployersByUserType: function () {
      const _vm = this;
      _vm
        .$axios({
          url: process.env.VUE_APP_SERVER_API + "api/SpointEmployerProvider",
          method: "GET",
        })
        .then((response) => {
          try {
            const employersList = response.data.message.employers;
            let empArr = [];
            switch (localStorage.getItem("selectedUserType") * 1) {
              case _vm.$func.usertype.distributor:
              case _vm.$func.usertype.beamSuntoryContractor: {
                employersList.Dist_ID.forEach((element) => {
                  empArr.push(element);
                });
                break;
              }
              case _vm.$func.usertype.tradePartner:
              case _vm.$func.usertype.promoAgency: {
                employersList.Trade_ID.forEach((element) => {
                  empArr.push(element);
                });
                break;
              }
              default:
                break;
            }
            _vm.masterRecordSet.item2 = empArr;
          } catch (ex) {
            console.warn(ex);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
span {
  color: #720303;
}
.bgwhite {
  background: #ffffff;
}
.lefttext {
  text-align: left !important;
}
.btn-outline-primary {
  color: #fff;
  border-color: #9d1c31;
  background-color: #9d1c31;
  height: 25px;
  padding-bottom: 30px;
}
.fill {
  min-height: 100vh;
  height: 100%;
}
.wocommerce-heading {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  text-align: center;
}
.mx-datepicker {
  position: relative !important;
  display: block !important;
  width: 100% !important;
}
.text-danger {
  color: #9d1c31 !important;
}
</style>
